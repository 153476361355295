let serviceEnv;
const host = window.location.hostname;

// env based set credentials of the application
const IntEnv = 'integration';
const ProdEnv = 'production';
const FedEnv = 'fedramp';
if (host.includes('expert-on-demand-int.webex.com') || host.includes('expert-on-demand-loadtest.webex.com')) {
  serviceEnv = IntEnv;
} else if (host.includes('expert-on-demand.webex.com')){
  serviceEnv = ProdEnv;
} else if (host.includes('expert-on-demand-usgov.webex.com')){
  serviceEnv = FedEnv;
} else {
  serviceEnv = process.env.SERVICE_ENV || ProdEnv;
}

export const config = {
    credentials: {
      clientType: 'confidential',
      v3_client_id: serviceEnv ===  ProdEnv || serviceEnv === FedEnv ? process.env.V3_PROD_CLIENT_ID : process.env.V3_INT_CLIENT_ID,
      v3_u2c_url: serviceEnv === ProdEnv ? process.env.V3_PROD_U2C_URI : serviceEnv === FedEnv ? process.env.FEDRAMP_V3_PROD_U2C_URI : process.env.V3_INT_U2C_URI,
      v3_scopes: serviceEnv === ProdEnv || serviceEnv === FedEnv ? process.env.V3_PROD_SCOPES : process.env.V3_INT_SCOPES,
      redirect_uri: serviceEnv === ProdEnv ? process.env.PROD_REDIRECT_URI : serviceEnv === FedEnv ? process.env.FEDRAMP_PROD_REDIRECT_URI : process.env.INT_REDIRECT_URI,
    },
};