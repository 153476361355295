//Sample application demonstrating how to perform a Spark OAuth2 authentication and Spark API access token request
//Note: this page must be accessed from a web server, Step #3 below may fail if loaded from the file system
import * as $ from 'jquery';
import qrcode from './libs/jquery.qrcode';
import { startTimer } from './libs/timer';
import { config } from './config';
import { SHA256 } from './libs/SHA256';

var appRedirectUri = config.credentials.redirect_uri;
var v3AppClientId = config.credentials.v3_client_id;
var v3U2cUrl = config.credentials.v3_u2c_url;
var v3AppScopes = config.credentials.v3_scopes;

// Helper function that generates a random alpha/numeric string
var randomString = function (length) {
    var str = "";
    var range = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < length; i++) {
        str += range.charAt(Math.floor(Math.random() * range.length));
    }
    return str;
}

// Helper function that parses a query string into a dictionary object
var parseQueryStr = function (queryString) {
    var params = {},
        keyvals, tmp, i, l;
    keyvals = queryString.split("&"); // Split out key/value pairs
    for (i = 0, l = keyvals.length; i < l; i++) { // Split key/value strings into a dictionary object
        tmp = keyvals[i].split('=');
        params[tmp[0]] = tmp[1];
    }
    return params;
};


$(".username").on('input', function () {
    $(".username").removeClass("username-error");
    $(".empty-username-error").css('display', 'none');
    $(".text-invalid-error").css('display', 'none');
});

//Handle 'Enter' click to sign in after username input
$(".username").keyup(function (event) {
    if (event.keyCode === 13) {
        $("#signInBtn").click();
    }
});

function isEmailValid(email) {
    // var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    var pattern = new RegExp(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/);
    return pattern.test(email);
}

// Step #1: Fires when the user clicks the 'Request Auth Code' button
function codeClick() {
    //Build the request URL based on the environment and also based on the version of the SDK consumed in the Application
    var requestUrl = '';
    //Building the url to accommodate app's consuming sdk v3 to login
    //In this case making a request to u2c service to aquire the base url before building the request url
    var username = $(".username").val().toLowerCase();
    window.sessionStorage.setItem("username", username);
    if ($(".username").val() != "") {
        if (isEmailValid(username)) {
            $.get(`${v3U2cUrl}?emailhash=${SHA256(username)}`, (data) => {
                requestUrl = `${data.services[0].serviceUrls[0].baseUrl}/idb/oauth2/v1/authorize?cisKeepMeSignedInOption=1&response_type=code&client_id=${encodeURIComponent(v3AppClientId)}&redirect_uri=${encodeURIComponent(appRedirectUri)}&state=${randomString(32)}&scope=${encodeURIComponent(v3AppScopes)}&cisService=webex&emailHash=${SHA256(username)}&token_version=2`;
                window.open(requestUrl, "_self"); // Redirect the browser to the OAuth2 kickoff URL
            });
        } else {
            $(".username").addClass("username-error");
            $(".text-invalid-error").css('display', 'flex')
        }
    } else {
        $(".username").addClass("username-error");
        $(".empty-username-error").css('display', 'flex')
        // alert("Please enter the username");
    }
};  

// Step #2: On page load, check if the 'code=' query param is present
// If so user has already authenticated, and  page has been reloaded via the Redirect URI
window.onload = function (e) {
    var params = parseQueryStr(window.location.search.substring(1)); // Parse the query string params into a dictionary
    if (params['code']) { // If the query param 'code' exists, then...
        startTimer.start( () => {
            $('.qr-code-expired').css("display", "flex");
            $('.scan-code').css("display", "block");
            $('.generate-code').css("display", "none"); 
        })
        var codeAndEmail = params['code'] + '&' + window.sessionStorage.getItem("username");

        window.history.replaceState(null, null, window.location.origin);

        $(function () {
            $('#qrcode').addClass('qrcode-displayed');
            $('.onboarding-form-logo-section').css('display', 'none');
            $('.input-and-button').css("display", "none");
            $('#backButton').css('display', 'flex');
            $('.onboarding-form-logo').css("display", "none");
            $('.scan-code').css("display", "block");
            $('.generate-code').css("display", "none");
            $('.display-remain-time').css("visibility", "visible");
            $('.circle').css("visibility", "visible");
            $('#qrcode').qrcode(codeAndEmail);

            $('#container-circle').css("display", "inline-flex");
            if (window.innerHeight < 600) {
                $('.mobile-timer').attr("width", "40");
            }

        });
    }
    if (params['error']) { // If the query param 'error' exists, then something went wrong...
        alert('Error requesting auth code: ' + params['error'] + ' / ' + params['error_description']);
    }
    window.sessionStorage.removeItem('username');
}

$(document).ready(function () {
    $(window).resize(function () {
        if (window.innerHeight < 600) {
            $('.mobile-timer').attr("width", "40");
        } else {
            $('.mobile-timer').attr("width", "70");
        }
    });
    const includes = $('[data-include-file]');
    $.each(includes, function () {
      const file = './' + $(this).data('include-file') + '.html';
      $(this).load(file);
    })
    $('#signInBtn').click(codeClick);
});

$('.close-banner').click(function(){
    $('.webex-alert-banner').css('display', 'none')
})

$('#resetBtn').click(function () {
    startTimer.hide();
    $('.qr-code-expired').css("display", "none");
    $('.onboarding-form-logo-section').css('display', 'flex');
    $('#onboarding-form-content').css("display", "block");
    $('#backButton').css('display', 'none');
    $('.onboarding-form-logo').css("display", "");
    $('.scanCodeText').css("display", "none");
    $('.scan-code').css("display", "none");
    $('.generate-code').css("display", "block");
    $('.input-and-button').css("display", "block");
    $('.title').css("display", "block");
});